<template>
    <div id="handMadeFrom">
        <div id="box">
            <div class="ManageTop">
                <div class="title">
                    <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px; margin-right: 5px" />
                    录入信息
                </div>
                <div class="hardTop">
                    <div>
                        <span class="TitleText">客户名称</span>
                        <span class="ml-2">
                            {{ company_name }}
                        </span>
                    </div>
                    <div class="box">
                        <span class="TitleText">报表类型</span>
                        <el-select disabled v-model="BType" size="small">
                            <el-option v-for="item in pageTypeList" :key="item.key" :label="item.value" :value="item.key">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="box">
                        <span class="TitleText">报表日期</span>
                        <el-date-picker disabled :value-format="BType == '00' ? 'yyyy' : 'yyyy-MM'" size="small"
                            v-model="dateTime" :type="BType == '00' ? 'year' : 'month'" :popper-append-to-body="false">
                        </el-date-picker>
                    </div>
                    <div class="box">
                        <span class="TitleText">报表种类</span>
                        <el-select disabled v-model="codetype" size="small">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="ManageBottom">
                <el-tabs v-show="codetype == '01'">
                    <el-tab-pane label="资产负债表" name="0">
                        <el-form ref="form" label-width="80px">
                            <div class="row">
                                <el-table :data="balanceSheet[0]" :summary-method="getSummariesOne" show-summary
                                    style="width: 50%; color: #333; font-size: 14px" :header-cell-style="{
                                        background: '#E9F3FF',
                                        color: '#333',
                                        height: '48px',
                                    }">
                                    <el-table-column prop="name" label="资产"></el-table-column>
                                    <el-table-column prop="value2" label="年初余额(元)">
                                        <template slot-scope="{ row }">
                                            <div v-if="row.name == '流动资产：'" style="height: 41px"></div>
                                            <el-form-item v-else>
                                                <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2"
                                                    :min="-Infinity" :max="Infinity" :controls="false" placeholder="请输入"
                                                    size="mini" style="width: 80%" v-model="row.value2">
                                                </el-input-number>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="value1" label="期末余额(元)">
                                        <template slot-scope="{ row }">
                                            <div v-if="row.name == '流动资产：'" style="height: 41px"></div>
                                            <el-form-item v-else>
                                                <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2"
                                                    :min="-Infinity" :max="Infinity" :controls="false" :disabled="true"
                                                    placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                                                </el-input-number>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                </el-table>

                                <el-table :data="balanceSheet[1]" :summary-method="getSummariesTwo" show-summary
                                    style="width: 50%; color: #333; font-size: 14px" :header-cell-style="{
                                        background: '#E9F3FF',
                                        color: '#333',
                                        height: '48px',
                                    }">
                                    <el-table-column prop="name" label="负债和所有者权益(或股东权益)"></el-table-column>
                                    <el-table-column prop="value2" label="年初余额(元)">
                                        <template slot-scope="{ row }">
                                            <div v-if="row.name == '流动负债：'" style="height: 41px"></div>
                                            <el-form-item v-else>
                                                <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2"
                                                    :min="-Infinity" :max="Infinity" :controls="false" placeholder="请输入"
                                                    size="mini" style="width: 80%" v-model="row.value2">
                                                </el-input-number>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="value1" label="期末余额(元)">
                                        <template slot-scope="{ row }">
                                            <div v-if="row.name == '流动负债：'" style="height: 41px"></div>
                                            <el-form-item v-else>
                                                <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2"
                                                    :min="-Infinity" :max="Infinity" :controls="false" :disabled="true"
                                                    placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                                                </el-input-number>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>

                            <div class="row tablesBox">
                                <div style="width: 50%">
                                    <el-table :data="balanceSheet[2]" :summary-method="getSummariesThree" show-summary
                                        style="color: #333; font-size: 14px" :header-cell-style="{
                                            background: '#E9F3FF',
                                            height: '30px',
                                        }">
                                        <el-table-column prop="name"> </el-table-column>
                                        <el-table-column prop="value2">
                                            <template slot-scope="{ row }">
                                                <div v-if="row.name == '非流动资产：'" style="height: 41px"></div>
                                                <el-form-item v-else>
                                                    <el-input-number @keydown.enter.native="nextFocus($event)"
                                                        :precision="2" :min="-Infinity" :max="Infinity" :controls="false"
                                                        placeholder="请输入" size="mini" style="width: 80%"
                                                        v-model="row.value2">
                                                    </el-input-number>
                                                </el-form-item>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="value1">
                                            <template slot-scope="{ row }">
                                                <div v-if="row.name == '非流动资产：'" style="height: 41px"></div>
                                                <el-form-item v-else>
                                                    <el-input-number @keydown.enter.native="nextFocus($event)"
                                                        :precision="2" :min="-Infinity" :max="Infinity" :controls="false"
                                                        :disabled="true" placeholder="请输入" size="mini" style="width: 80%"
                                                        v-model="row.value1">
                                                    </el-input-number>
                                                </el-form-item>
                                            </template>
                                        </el-table-column>
                                    </el-table>

                                    <div class="count">
                                        <span style="flex: 1">资产总计</span>
                                        <span style="flex: 1">{{
                                            numFilter(
                                                Number(TotalCurrentAssets[1]) + Number(FlagTotalCurrentAssets[1])) || "0.00"
                                        }}</span>
                                        <span style="flex: 1">{{ numFilter(
                                            Number(TotalCurrentAssets[2]) + Number(FlagTotalCurrentAssets[2])) || "0.00"
                                        }}</span>
                                    </div>
                                </div>
                                <div style="width: 50%">
                                    <el-table :data="balanceSheet[3]" :summary-method="getSummariesFour" show-summary
                                        style="color: #333; font-size: 14px" :header-cell-style="{
                                            background: '#E9F3FF',
                                            color: '#333',
                                            height: '2px',
                                        }">
                                        <el-table-column prop="name">
                                            <template slot-scope="{ row }">
                                                <span v-if="row.code == '1920n'">
                                                    &emsp;&emsp;&emsp;{{ row.name }}
                                                </span>
                                                <span v-else>
                                                    {{ row.name }}
                                                </span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="value2">
                                            <template slot-scope="{ row }">
                                                <div v-if="row.name == '非流动负债：'" style="height: 41px"></div>
                                                <el-form-item v-else>
                                                    <el-input-number @keydown.enter.native="nextFocus($event)"
                                                        :precision="2" :min="-Infinity" :max="Infinity" :controls="false"
                                                        placeholder="请输入" size="mini" style="width: 80%"
                                                        v-model="row.value2">
                                                    </el-input-number>
                                                </el-form-item>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="value1">
                                            <template slot-scope="{ row }">
                                                <div v-if="row.name == '非流动负债：'" style="height: 41px"></div>
                                                <el-form-item v-else>
                                                    <el-input-number @keydown.enter.native="nextFocus($event)"
                                                        :precision="2" :min="-Infinity" :max="Infinity" :controls="false"
                                                        :disabled="true" placeholder="请输入" size="mini" style="width: 80%"
                                                        v-model="row.value1">
                                                    </el-input-number>
                                                </el-form-item>
                                            </template>
                                        </el-table-column>
                                    </el-table>

                                    <div class="count">
                                        <span style="flex: 1">负债合计</span>
                                        <span style="flex: 1">{{
                                            numFilter(
                                                Number(TotalCurrentLiability[1]) + Number(FlagTotalCurrentLiability[1])) ||
                                            "0.00"
                                        }}</span>
                                        <span style="flex: 1">{{
                                            numFilter(
                                                Number(TotalCurrentLiability[2]) + Number(FlagTotalCurrentLiability[2])) ||
                                            "0.00"
                                        }}</span>
                                    </div>
                                    <el-table :data="balanceSheet[4]" :summary-method="getSummariesFive" show-summary
                                        style="color: #333; font-size: 14px" :header-cell-style="{
                                            background: '#E9F3FF',
                                            color: '#333',
                                            height: '52px',
                                        }">
                                        <el-table-column prop="name">
                                            <template slot-scope="{ row }">
                                                <span v-if="row.code == '1428n'">
                                                    &emsp;&emsp;&emsp;{{ row.name }}
                                                </span>
                                                <span v-else>
                                                    {{ row.name }}
                                                </span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="value2">
                                            <template slot-scope="{ row }">
                                                <div v-if="row.name == '所有者权益(或股东权益)：'" style="height: 41px"></div>
                                                <el-form-item v-else>
                                                    <el-input-number @keydown.enter.native="nextFocus($event)"
                                                        :precision="2" :min="-Infinity" :max="Infinity" :controls="false"
                                                        placeholder="请输入" size="mini" style="width: 80%"
                                                        v-model="row.value2">
                                                    </el-input-number>
                                                </el-form-item>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="value1">
                                            <template slot-scope="{ row }">
                                                <div v-if="row.name == '所有者权益(或股东权益)：'" style="height: 41px"></div>
                                                <el-form-item v-else>
                                                    <el-input-number @keydown.enter.native="nextFocus($event)"
                                                        :precision="2" :min="-Infinity" :max="Infinity" :controls="false"
                                                        :disabled="true" placeholder="请输入" size="mini" style="width: 80%"
                                                        v-model="row.value1">
                                                    </el-input-number>
                                                </el-form-item>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <div class="count">
                                        <span style="flex: 1">负债和所有者权益(或股东权益)合计</span>
                                        <span style="flex: 1">{{
                                            numFilter(Number(TotalCurrentLiability[1]) +
                                                Number(FlagTotalCurrentLiability[1]) +
                                                Number(ownershipInterest[1]))
                                            || "0.00"
                                        }}</span>
                                        <span style="flex: 1">{{
                                            numFilter(
                                                Number(TotalCurrentLiability[2]) +
                                                Number(FlagTotalCurrentLiability[2]) +
                                                Number(ownershipInterest[2])) || "0.00"
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </el-form>
                    </el-tab-pane>

                </el-tabs>
                <el-tabs v-show="codetype == '00'">
                    <el-tab-pane label="资产负债表" name="0">
                        <el-form ref="form" label-width="80px">
                            <div class="row">
                                <!-- ----流动资产：---- -->
                                <el-table :data="balanceSheetMerge[0]" :summary-method="getSummariesOnes" show-summary
                                    style="width: 50%; color: #333; font-size: 14px" :header-cell-style="{
                                        background: '#E9F3FF',
                                        color: '#333',
                                        height: '48px',
                                    }">
                                    <el-table-column prop="name" label="资产"></el-table-column>
                                    <el-table-column prop="value2" label="年初余额(元)">
                                        <template slot-scope="{ row }">
                                            <div v-if="row.name == '流动资产：'" style="height: 41px"></div>
                                            <el-form-item v-else>
                                                <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2"
                                                    :min="-Infinity" :max="Infinity" :controls="false" placeholder="请输入"
                                                    size="mini" style="width: 80%" v-model="row.value2">
                                                </el-input-number>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="value1" label="期末余额(元)">
                                        <template slot-scope="{ row }">
                                            <div v-if="row.name == '流动资产：'" style="height: 41px"></div>
                                            <el-form-item v-else>
                                                <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2"
                                                    :min="-Infinity" :max="Infinity" :controls="false" :disabled="true"
                                                    placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                                                </el-input-number>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                </el-table>

                                <el-table :data="balanceSheetMerge[1]" :summary-method="getSummariesTwos" show-summary
                                    style="width: 50%; color: #333; font-size: 14px" :header-cell-style="{
                                        background: '#E9F3FF',
                                        color: '#333',
                                        height: '48px',
                                    }">
                                    <el-table-column prop="name" label="负债和所有者权益(或股东权益)"></el-table-column>
                                    <el-table-column prop="value2" label="年初余额(元)">
                                        <template slot-scope="{ row }">
                                            <div v-if="row.name == '流动负债：'" style="height: 41px"></div>
                                            <el-form-item v-else>
                                                <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2"
                                                    :min="-Infinity" :max="Infinity" :controls="false" placeholder="请输入"
                                                    size="mini" style="width: 80%" v-model="row.value2">
                                                </el-input-number>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="value1" label="期末余额(元)">
                                        <template slot-scope="{ row }">
                                            <div v-if="row.name == '流动负债：'" style="height: 41px"></div>
                                            <el-form-item v-else>
                                                <el-input-number @keydown.enter.native="nextFocus($event)" :precision="2"
                                                    :min="-Infinity" :max="Infinity" :controls="false" :disabled="true"
                                                    placeholder="请输入" size="mini" style="width: 80%" v-model="row.value1">
                                                </el-input-number>
                                            </el-form-item>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>

                            <div class="row tablesBox">
                                <div style="width: 50%">
                                    <el-table :data="balanceSheetMerge[2]" :summary-method="getSummariesThrees" show-summary
                                        style="color: #333; font-size: 14px" :header-cell-style="{
                                            background: '#E9F3FF',
                                            color: '#333',
                                            height: '48px',
                                        }">
                                        <el-table-column prop="name"> </el-table-column>
                                        <el-table-column prop="value2">
                                            <template slot-scope="{ row }">
                                                <div v-if="row.name == '非流动资产：'" style="height: 41px"></div>
                                                <el-form-item v-else>
                                                    <el-input-number @keydown.enter.native="nextFocus($event)"
                                                        :precision="2" :min="-Infinity" :max="Infinity" :controls="false"
                                                        placeholder="请输入" size="mini" style="width: 80%"
                                                        v-model="row.value2">
                                                    </el-input-number>
                                                </el-form-item>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="value1">
                                            <template slot-scope="{ row }">
                                                <div v-if="row.name == '非流动资产：'" style="height: 41px"></div>
                                                <el-form-item v-else>
                                                    <el-input-number @keydown.enter.native="nextFocus($event)"
                                                        :precision="2" :min="-Infinity" :max="Infinity" :controls="false"
                                                        :disabled="true" placeholder="请输入" size="mini" style="width: 80%"
                                                        v-model="row.value1">
                                                    </el-input-number>
                                                </el-form-item>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <!-- 资产总计 -->
                                    <div class="count">
                                        <span style="flex: 1">资产总计</span>
                                        <span style="flex: 1">{{
                                            numFilter(
                                                Number(TotalCurrentAssetss[1]) + Number(FlagTotalCurrentAssetss[1])
                                            ) || "0.00"
                                        }}</span>
                                        <span style="flex: 1">{{
                                            numFilter(
                                                Number(TotalCurrentAssetss[2]) + Number(FlagTotalCurrentAssetss[2])
                                            ) || '0.00'
                                        }}</span>
                                    </div>
                                </div>
                                <div style="width: 50%">
                                    <el-table :data="balanceSheetMerge[3]" :summary-method="getSummariesFours" show-summary
                                        style="color: #333; font-size: 14px" :header-cell-style="{
                                            background: '#E9F3FF',
                                            color: '#333',
                                            height: '48px',
                                        }">
                                        <el-table-column prop="name">
                                            <template slot-scope="{ row }">
                                                <span v-if="row.code == '1920n'">
                                                    &emsp;&emsp;&emsp;{{ row.name }}
                                                </span>
                                                <span v-else>
                                                    {{ row.name }}
                                                </span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="value2">
                                            <template slot-scope="{ row }">
                                                <div v-if="row.name == '非流动负债：'" style="height: 41px"></div>
                                                <el-form-item v-else>
                                                    <el-input-number @keydown.enter.native="nextFocus($event)"
                                                        :precision="2" :min="-Infinity" :max="Infinity" :controls="false"
                                                        placeholder="请输入" size="mini" style="width: 80%"
                                                        v-model="row.value2">
                                                    </el-input-number>
                                                </el-form-item>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="value1">
                                            <template slot-scope="{ row }">
                                                <div v-if="row.name == '非流动负债：'" style="height: 41px"></div>
                                                <el-form-item v-else>
                                                    <el-input-number @keydown.enter.native="nextFocus($event)"
                                                        :precision="2" :min="-Infinity" :max="Infinity" :controls="false"
                                                        disabled placeholder="请输入" size="mini" style="width: 80%"
                                                        v-model="row.value1">
                                                    </el-input-number>
                                                </el-form-item>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <!-- 负债总合计 -->
                                    <div class="count">
                                        <span style="flex: 1">负债合计</span>
                                        <span style="flex: 1">{{
                                            numFilter(
                                                Number(TotalCurrentLiabilitys[1]) +
                                                Number(FlagTotalCurrentLiabilitys[1])
                                            ) || "0.00"
                                        }}</span>
                                        <span style="flex: 1">{{
                                            numFilter(
                                                Number(TotalCurrentLiabilitys[2]) +
                                                Number(FlagTotalCurrentLiabilitys[2])
                                            ) || "0.00"
                                        }}</span>
                                    </div>
                                    <el-table :data="balanceSheetMerge[4]" ref="syzqy" :summary-method="getSummariesFives"
                                        show-summary style="color: #333; font-size: 14px" :header-cell-style="{
                                            background: '#E9F3FF',
                                            color: '#333',
                                            height: '52px',
                                        }">
                                        <el-table-column prop="name">
                                            <template slot-scope="{ row }">
                                                <span v-if="row.code == '1428n'">
                                                    &emsp;&emsp;&emsp;{{ row.name }}
                                                </span>
                                                <span v-else :style="row.code == '1430h' ? { fontWeight: 550 } : ''">
                                                    {{ row.name }}
                                                </span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="value2">
                                            <template slot-scope="{ row }">
                                                <div v-if="row.name == '所有者权益(或股东权益)：'" style="height: 41px"></div>
                                                <el-form-item v-else>
                                                    <el-input-number v-if="row.code !== '1430h'" @blur="qyhj"
                                                        @keydown.enter.native="nextFocus($event)" :precision="2"
                                                        :min="-Infinity" :max="Infinity" :controls="false" placeholder="请输入"
                                                        size="mini" style="width: 80%" v-model="row.value2">
                                                    </el-input-number>
                                                    <div v-else style="font-weight:550">
                                                        <span>{{ parseFloat(row.value2).toFixed(2) }} </span>
                                                    </div>
                                                </el-form-item>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="value1">
                                            <template slot-scope="{ row }">
                                                <div v-if="row.name == '所有者权益(或股东权益)：'" style="height: 41px"></div>
                                                <el-form-item v-else>
                                                    <el-input-number v-if="row.code !== '1430h'" @blur="qyhj"
                                                        @keydown.enter.native="nextFocus($event)" :precision="2"
                                                        :min="-Infinity" :max="Infinity" :controls="false" disabled
                                                        placeholder="请输入" size="mini" style="width: 80%"
                                                        v-model="row.value1">
                                                    </el-input-number>
                                                    <div v-else style="font-weight:550">
                                                        <span>{{ parseFloat(row.value1).toFixed(2) }} </span>
                                                    </div>
                                                </el-form-item>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <div class="count">
                                        <span style="flex: 1">负债和所有者权益(或股东权益)合计</span>
                                        <span style="flex: 1">{{
                                            numFilter(Number(TotalCurrentLiabilitys[1]) +
                                                Number(FlagTotalCurrentLiabilitys[1]) +
                                                Number(ownershipInterests[1]))
                                            || "0.00"
                                        }}</span>
                                        <span style="flex: 1">{{
                                            numFilter(
                                                Number(TotalCurrentLiabilitys[2]) +
                                                Number(FlagTotalCurrentLiabilitys[2]) +
                                                Number(ownershipInterests[2])) || "0.00"
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div class="flexBottom">
            <el-button size="small" @click="back"
                style="color:#1989fa;border:1px solid #1989fa;width:120px;font-size:14px">取 消</el-button>
            <el-button size="small" type="primary" @click="onSubmit"
                style="width: 120px; font-size: 14px">提交资表数据</el-button>
        </div>
    </div>
</template>
  
<script>
import { hand_save } from "@/api";
import * as sesstion from "@/store/localStorage";
export default {
    name: "balanceSheet",
    data() {
        return {
            inputDoms: '',
            nextNum: 0,
            balanceSheet: [
                // 资产负债表--流动资产
                [
                    {
                        name: "流动资产：",
                    },
                    {
                        name: "货币资金",
                        value1: "",
                        value2: "",
                        code: "1001",
                    },
                    {
                        name: "交易性金融资产",
                        value1: "",
                        value2: "",
                        code: "1239",
                    },
                    {
                        name: "衍生金融资产",
                        value1: "",
                        value2: "",
                        code: "1411",
                    },
                    {
                        name: "应收票据",
                        value1: "",
                        value2: "",
                        code: "1003",
                    },
                    {
                        name: "应收账款",
                        value1: "",
                        value2: "",
                        code: "1008",
                    },
                    {
                        name: "应收款项融资",
                        value1: "",
                        value2: "",
                        code: "1657",
                    },
                    {
                        name: "预付款项",
                        value1: "",
                        value2: "",
                        code: "1009",
                    },
                    {
                        name: "其他应收款",
                        value1: "",
                        value2: "",
                        code: "1210",
                    },
                    {
                        name: "存货",
                        value1: "",
                        value2: "",
                        code: "1014",
                    },
                    {
                        name: "合同资产",
                        value1: "",
                        value2: "",
                        code: "1658",
                    },
                    {
                        name: "持有待售资产",
                        value1: "",
                        value2: "",
                        code: "1821",
                    },
                    {
                        name: "一年内到期的非流动资产",
                        value1: "",
                        value2: "",
                        code: "1241",
                    },
                    {
                        name: "其他流动资产",
                        value1: "",
                        value2: "",
                        code: "1213",
                    },
                ],
                // 资产负债表--流动负债
                [
                    {
                        name: "流动负债：",
                    },
                    {
                        name: "短期借款",
                        value1: "",
                        value2: "",
                        code: "1047",
                    },
                    {
                        name: "交易性金融负债",
                        value1: "",
                        value2: "",
                        code: "1255",
                    },
                    {
                        name: "衍生金融负债",
                        value1: "",
                        value2: "",
                        code: "1422",
                    },
                    {
                        name: "应付票据",
                        value1: "",
                        value2: "",
                        code: "1048",
                    },
                    {
                        name: "应付账款",
                        value1: "",
                        value2: "",
                        code: "1049",
                    },
                    {
                        name: "预收款项",
                        value1: "",
                        value2: "",
                        code: "1050",
                    },
                    {
                        name: "合同负债",
                        value1: "",
                        value2: "",
                        code: "1663",
                    },
                    {
                        name: "应付职工薪酬",
                        value1: "",
                        value2: "",
                        code: "1258",
                    },
                    {
                        name: "应交税费",
                        value1: "",
                        value2: "",
                        code: "1259",
                    },
                    {
                        name: "其他应付款",
                        value1: "",
                        value2: "",
                        code: "1056",
                    },
                    {
                        name: "持有待售负债",
                        value1: "",
                        value2: "",
                        code: "1696",
                    },
                    {
                        name: "一年内到期的非流动负债",
                        value1: "",
                        value2: "",
                        code: "1262",
                    },
                    {
                        name: "其他流动负债",
                        value1: "",
                        value2: "",
                        code: "1060",
                    },
                ],
                // 资产负债表--非流动资产
                [
                    {
                        name: "非流动资产：",
                    },
                    {
                        name: "债权投资",
                        value1: "",
                        value2: "",
                        code: "1659",
                    },
                    {
                        name: "其他债权投资",
                        value1: "",
                        value2: "",
                        code: "1660",
                    },
                    {
                        name: "长期应收款",
                        value1: "",
                        value2: "",
                        code: "1244",
                    },
                    {
                        name: "长期股权投资",
                        value1: "",
                        value2: "",
                        code: "1245",
                    },
                    {
                        name: "其他权益工具投资",
                        value1: "",
                        value2: "",
                        code: "1661",
                    },
                    {
                        name: "其他非流动金融资产",
                        value1: "",
                        value2: "",
                        code: "1662",
                    },
                    {
                        name: "投资性房地产",
                        value1: "",
                        value2: "",
                        code: "1246",
                    },
                    {
                        name: "固定资产",
                        value1: "",
                        value2: "",
                        code: "1030",
                    },
                    {
                        name: "在建工程",
                        value1: "",
                        value2: "",
                        code: "1034",
                    },
                    {
                        name: "生产性生物资产",
                        value1: "",
                        value2: "",
                        code: "1247",
                    },
                    {
                        name: "油气资产",
                        value1: "",
                        value2: "",
                        code: "1248",
                    },
                    {
                        name: "使用权资产",
                        value1: "",
                        value2: "",
                        code: "1822",
                    },
                    {
                        name: "无形资产",
                        value1: "",
                        value2: "",
                        code: "1041",
                    },
                    {
                        name: "开发支出",
                        value1: "",
                        value2: "",
                        code: "1249",
                    },
                    {
                        name: "商誉",
                        value1: "",
                        value2: "",
                        code: "1250",
                    },
                    {
                        name: "长期待摊费用",
                        value1: "",
                        value2: "",
                        code: "1251",
                    },
                    {
                        name: "递延所得税资产",
                        value1: "",
                        value2: "",
                        code: "1252",
                    },
                    {
                        name: "其他非流动资产",
                        value1: "",
                        value2: "",
                        code: "1253",
                    },
                ],
                // 资产负债表--非流动负债
                [
                    {
                        name: "非流动负债：",
                    },
                    {
                        name: "长期借款",
                        value1: "",
                        value2: "",
                        code: "1062",
                    },
                    {
                        name: "应付债券",
                        value1: "",
                        value2: "",
                        code: "1063",
                    },
                    {
                        name: "其中：优先股",
                        value1: "",
                        value2: "",
                        code: "1919n",
                    },
                    {
                        name: "永续债",
                        value1: "",
                        value2: "",
                        code: "1920n",
                    },
                    {
                        name: "租赁负债",
                        value1: "",
                        value2: "",
                        code: "1697",
                    },
                    {
                        name: "长期应付款",
                        value1: "",
                        value2: "",
                        code: "1064",
                    },
                    {
                        name: "预计负债",
                        value1: "",
                        value2: "",
                        code: "1263",
                    },
                    {
                        name: "递延收益",
                        value1: "",
                        value2: "",
                        code: "1611",
                    },
                    {
                        name: "递延所得税负债",
                        value1: "",
                        value2: "",
                        code: "1264",
                    },
                    {
                        name: "其他非流动负债",
                        value1: "",
                        value2: "",
                        code: "1265",
                    },
                ],
                // 资产负债表--所有者权益
                [
                    {
                        name: "所有者权益(或股东权益)：",
                    },
                    {
                        name: "实收资本(或股本)",
                        value1: "",
                        value2: "",
                        code: "1071",
                    },
                    {
                        name: "其他权益工具",
                        value1: "",
                        value2: "",
                        code: "1268",
                    },
                    {
                        name: "其中：优先股",
                        value1: "",
                        value2: "",
                        code: "1427n",
                    },
                    {
                        name: "永续债",
                        value1: "",
                        value2: "",
                        code: "1428n",
                    },
                    {
                        name: "资本公积",
                        value1: "",
                        value2: "",
                        code: "1072",
                    },
                    {
                        name: "减：库存股",
                        value1: "",
                        value2: "",
                        code: "1269j",
                    },
                    {
                        name: "其他综合收益",
                        value1: "",
                        value2: "",
                        code: "1270",
                    },
                    {
                        name: "专项储备",
                        value1: "",
                        value2: "",
                        code: "1615",
                    },
                    {
                        name: "盈余公积",
                        value1: "",
                        value2: "",
                        code: "1073",
                    },
                    {
                        name: "未分配利润",
                        value1: "",
                        value2: "",
                        code: "1075",
                    },
                ],
            ],
            // 合并报表
            // 资产负债表
            balanceSheetMerge: [
                // 资产负债表--流动资产
                [
                    {
                        name: "流动资产：",
                    },
                    {
                        name: "货币资金",
                        value1: "",
                        value2: "",
                        code: "1001",
                    },
                    {
                        name: "结算备付金",
                        value1: "",
                        value2: "",
                        code: "1464",
                    },
                    {
                        name: "拆出资金",
                        value1: "",
                        value2: "",
                        code: "1409",
                    },
                    {
                        name: "交易性金融资产",
                        value1: "",
                        value2: "",
                        code: "1239",
                    },
                    {
                        name: "衍生金融资产",
                        value1: "",
                        value2: "",
                        code: "1411",
                    },
                    {
                        name: "应收票据",
                        value1: "",
                        value2: "",
                        code: "1003",
                    },
                    {
                        name: "应收账款",
                        value1: "",
                        value2: "",
                        code: "1008",
                    },
                    {
                        name: "应收款项融资",
                        value1: "",
                        value2: "",
                        code: "1657",
                    },
                    {
                        name: "预付款项",
                        value1: "",
                        value2: "",
                        code: "1009",
                    },
                    {
                        name: "应收保费",
                        value1: "",
                        value2: "",
                        code: "1494",
                    },
                    {
                        name: "应收分保账款",
                        value1: "",
                        value2: "",
                        code: "1496",
                    },
                    {
                        name: "应收分保合同准备金",
                        value1: "",
                        value2: "",
                        code: "1578",
                    },
                    {
                        name: "其他应收款",
                        value1: "",
                        value2: "",
                        code: "1210",
                    },
                    {
                        name: "买入返售金融资产",
                        value1: "",
                        value2: "",
                        code: "1412",
                    },
                    {
                        name: "存货",
                        value1: "",
                        value2: "",
                        code: "1014",
                    },
                    {
                        name: "合同资产",
                        value1: "",
                        value2: "",
                        code: "1658",
                    },
                    {
                        name: "持有待售资产",
                        value1: "",
                        value2: "",
                        code: "1821",
                    },
                    {
                        name: "一年内到期的非流动资产",
                        value1: "",
                        value2: "",
                        code: "1241",
                    },
                    {
                        name: "其他流动资产",
                        value1: "",
                        value2: "",
                        code: "1213",
                    },
                ],
                // 资产负债表--流动负债
                [
                    {
                        name: "流动负债：",
                    },
                    {
                        name: "短期借款",
                        value1: "",
                        value2: "",
                        code: "1047",
                    },
                    {
                        name: "向中央银行借款",
                        value1: "",
                        value2: "",
                        code: "1418",
                    },
                    {
                        name: "拆入资金",
                        value1: "",
                        value2: "",
                        code: "1420",
                    },
                    {
                        name: "交易性金融负债",
                        value1: "",
                        value2: "",
                        code: "1255",
                    },
                    {
                        name: "衍生金融负债",
                        value1: "",
                        value2: "",
                        code: "1422",
                    },
                    {
                        name: "应付票据",
                        value1: "",
                        value2: "",
                        code: "1048",
                    },
                    {
                        name: "应付账款",
                        value1: "",
                        value2: "",
                        code: "1049",
                    },
                    {
                        name: "预收款项",
                        value1: "",
                        value2: "",
                        code: "1050",
                    },
                    {
                        name: "合同负债",
                        value1: "",
                        value2: "",
                        code: "1663",
                    },
                    {
                        name: "卖出回购金融资产款",
                        value1: "",
                        value2: "",
                        code: "1423",
                    },
                    {
                        name: "吸收存款及同业存放",
                        value1: "",
                        value2: "",
                        code: "1602",
                    },
                    {
                        name: "代理买卖证券款",
                        value1: "",
                        value2: "",
                        code: "1469",
                    },
                    {
                        name: "代理承销证券款",
                        value1: "",
                        value2: "",
                        code: "1470",
                    },
                    {
                        name: "应付职工薪酬",
                        value1: "",
                        value2: "",
                        code: "1258",
                    },
                    {
                        name: "应交税费",
                        value1: "",
                        value2: "",
                        code: "1259",
                    },
                    {
                        name: "其他应付款",
                        value1: "",
                        value2: "",
                        code: "1056",
                    },
                    {
                        name: "应付手续费及佣金",
                        value1: "",
                        value2: "",
                        code: "1506",
                    },
                    {
                        name: "应付分保账款",
                        value1: "",
                        value2: "",
                        code: "1507",
                    },
                    {
                        name: "持有待售负债",
                        value1: "",
                        value2: "",
                        code: "1696",
                    },
                    {
                        name: "一年内到期的非流动负债",
                        value1: "",
                        value2: "",
                        code: "1262",
                    },
                    {
                        name: "其他流动负债",
                        value1: "",
                        value2: "",
                        code: "1060",
                    },
                ],
                // 资产负债表--非流动资产
                [
                    {
                        name: "非流动资产：",
                    },
                    {
                        name: "发放贷款和垫款",
                        value1: "",
                        value2: "",
                        code: "1413",
                    },
                    {
                        name: "债权投资",
                        value1: "",
                        value2: "",
                        code: "1659",
                    },
                    {
                        name: "其他债权投资",
                        value1: "",
                        value2: "",
                        code: "1660",
                    },
                    {
                        name: "长期应收款",
                        value1: "",
                        value2: "",
                        code: "1244",
                    },
                    {
                        name: "长期股权投资",
                        value1: "",
                        value2: "",
                        code: "1245",
                    },
                    {
                        name: "其他权益工具投资",
                        value1: "",
                        value2: "",
                        code: "1661",
                    },
                    {
                        name: "其他非流动金融资产",
                        value1: "",
                        value2: "",
                        code: "1662",
                    },
                    {
                        name: "投资性房地产",
                        value1: "",
                        value2: "",
                        code: "1246",
                    },
                    {
                        name: "固定资产",
                        value1: "",
                        value2: "",
                        code: "1030",
                    },
                    {
                        name: "在建工程",
                        value1: "",
                        value2: "",
                        code: "1034",
                    },
                    {
                        name: "生产性生物资产",
                        value1: "",
                        value2: "",
                        code: "1247",
                    },
                    {
                        name: "油气资产",
                        value1: "",
                        value2: "",
                        code: "1248",
                    },
                    {
                        name: "使用权资产",
                        value1: "",
                        value2: "",
                        code: "1822",
                    },
                    {
                        name: "无形资产",
                        value1: "",
                        value2: "",
                        code: "1041",
                    },
                    {
                        name: "开发支出",
                        value1: "",
                        value2: "",
                        code: "1249",
                    },
                    {
                        name: "商誉",
                        value1: "",
                        value2: "",
                        code: "1250",
                    },
                    {
                        name: "长期待摊费用",
                        value1: "",
                        value2: "",
                        code: "1251",
                    },
                    {
                        name: "递延所得税资产",
                        value1: "",
                        value2: "",
                        code: "1252",
                    },
                    {
                        name: "其他非流动资产",
                        value1: "",
                        value2: "",
                        code: "1253",
                    },
                ],
                // 资产负债表--非流动负债
                [
                    {
                        name: "非流动负债：",
                    },
                    {
                        name: "保险合同准备金",
                        value1: "",
                        value2: "",
                        code: "1605",
                    },
                    {
                        name: "长期借款",
                        value1: "",
                        value2: "",
                        code: "1062",
                    },
                    {
                        name: "应付债券",
                        value1: "",
                        value2: "",
                        code: "1063",
                    },
                    {
                        name: "其中：优先股",
                        value1: "",
                        value2: "",
                        code: "1919n",
                    },
                    {
                        name: "永续债",
                        value1: "",
                        value2: "",
                        code: "1920n",
                    },
                    {
                        name: "租赁负债",
                        value1: "",
                        value2: "",
                        code: "1697",
                    },
                    {
                        name: "长期应付款",
                        value1: "",
                        value2: "",
                        code: "1064",
                    },
                    {
                        name: "预计负债",
                        value1: "",
                        value2: "",
                        code: "1263",
                    },
                    {
                        name: "递延收益",
                        value1: "",
                        value2: "",
                        code: "1611",
                    },
                    {
                        name: "递延所得税负债",
                        value1: "",
                        value2: "",
                        code: "1264",
                    },
                    {
                        name: "其他非流动负债",
                        value1: "",
                        value2: "",
                        code: "1265",
                    },
                ],
                // 资产负债表--所有者权益
                [
                    {
                        name: "所有者权益(或股东权益)：",
                    },
                    {
                        name: "实收资本(或股本)",
                        value1: "",
                        value2: "",
                        code: "1071",
                    },
                    {
                        name: "其他权益工具",
                        value1: "",
                        value2: "",
                        code: "1268",
                    },
                    {
                        name: "其中：优先股",
                        value1: "",
                        value2: "",
                        code: "1427n",
                    },
                    {
                        name: "永续债",
                        value1: "",
                        value2: "",
                        code: "1428n",
                    },
                    {
                        name: "资本公积",
                        value1: "",
                        value2: "",
                        code: "1072",
                    },
                    {
                        name: "减：库存股",
                        value1: "",
                        value2: "",
                        code: "1269j",
                    },
                    {
                        name: "其他综合收益",
                        value1: "",
                        value2: "",
                        code: "1270",
                    },
                    {
                        name: "专项储备",
                        value1: "",
                        value2: "",
                        code: "1615",
                    },
                    {
                        name: "盈余公积",
                        value1: "",
                        value2: "",
                        code: "1073",
                    },
                    {
                        name: "一般风险准备",
                        value1: "",
                        value2: "",
                        code: "1429",
                    },
                    {
                        name: "未分配利润",
                        value1: "",
                        value2: "",
                        code: "1075",
                    },
                    {
                        name: "归属于母公司所有者权益（或股东权益）合计",
                        value1: "0",
                        value2: "0",
                        code: "1430h",
                    },
                    {
                        name: "少数股东权益",
                        value1: "",
                        value2: "",
                        code: "1070",
                    },
                ],
            ],

            // 资产负债表合计
            TotalCurrentAssets: [], //流动资产合计数组
            TotalCurrentLiability: [], //流动负债合计数组
            FlagTotalCurrentAssets: [], //非流动资产合计数组
            FlagTotalCurrentLiability: [], //非流动负债合计数组
            ownershipInterest: [], //所有者权益

            // 合并报表-资产负债
            TotalCurrentAssetss: [], //流动资产合计数组
            TotalCurrentLiabilitys: [], //流动负债合计数组
            FlagTotalCurrentAssetss: [], //非流动资产合计数组
            FlagTotalCurrentLiabilitys: [], //非流动负债合计数组
            ownershipInterests: [], //所有者权益

            codetype: "01",
            dateTime: "",
            activeName: "0",
            BType: "",
            company_name: sesstion.getLocalData("company").name,
            company_code: sesstion.getLocalData("company").company_code,
            options: [
                {
                    value: "00",
                    label: "合并报表",
                },
                {
                    value: "01",
                    label: "本部报表",
                },
            ],
            pageTypeList: [
                {
                    key: "00",
                    value: "年报",
                },
                {
                    key: "01",
                    value: "月报",
                },
            ],

        };
    },

    created() { },
    mounted() {
        this.getDataList();
        this.$nextTick(() => {
            this.initInputDOM()
        })
    },
    methods: {
        getDataList() {
            this.codetype = sesstion.getLocalData("sheet").report_type
            this.BType = sesstion.getLocalData("sheet").report_time
            this.dateTime = sesstion.getLocalData("sheet").report_year
            let sheet = sesstion.getLocalData("sheet").tableDataSheet
            sheet[0].pop();
            sheet[1].pop();
            sheet[2].pop();
            sheet[2].pop();
            sheet[3].pop();
            sheet[3].pop();
            sheet[4].pop();
            sheet[4].pop();
            let sheetArr = []
            sheet.forEach((item, index) => {
                var sheetData = []
                item.map((ele, i) => {
                    if (ele !== null) {
                        sheetData.push(ele)
                    }
                })
                sheetArr.push(sheetData)
            })
            if (this.codetype == "00") {
                this.balanceSheetMerge = sheetArr
            } else {
                this.balanceSheet = sheetArr
            }
        },


        focusIndex(e) {
            this.nextNum = e.target.getAttribute('data-index')
        },
        initInputDOM() {
            // 获取id为table-form下的所有input 框
            const inputDoms = document.querySelectorAll('.el-input__inner')
            // 遍历这个input框给他们一个标识
            inputDoms.forEach((item, index) => {
                item.setAttribute('data-index', index)
            })
            this.inputDoms = inputDoms
        },

        // 回车事件
        nextFocus(event) {
            const index = event.target.getAttribute('data-index')
            const nextIndex = parseInt(index) + 2
            const length = this.inputDoms.length
            if (nextIndex < length) {
                this.inputDoms[nextIndex].focus()
                this.inputDoms[nextIndex].select()
            } else {
                this.inputDoms[0].focus()
                this.inputDoms[nextIndex].select()
            }
        },
        numFilter(value) {
            if (value !== 0) {
                let realVal = Number(value).toFixed(2);
                return realVal;
            }
        },
        // 提交表单数据
        onSubmit() {
            if (this.codetype == "00") {
                this.balanceSheetMerge[0].push({
                    name: this.TotalCurrentAssetss[0],
                    value1: this.TotalCurrentAssetss[2],
                    value2: this.TotalCurrentAssetss[1],
                    code: "1019h",
                });
                this.balanceSheetMerge[1].push({
                    name: this.TotalCurrentLiabilitys[0],
                    value1: this.TotalCurrentLiabilitys[2],
                    value2: this.TotalCurrentLiabilitys[1],
                    code: "1061h",
                });
                this.balanceSheetMerge[2].push(
                    {
                        name: this.FlagTotalCurrentAssetss[0],
                        value1: this.FlagTotalCurrentAssetss[2],
                        value2: this.FlagTotalCurrentAssetss[1],
                        code: "1254h",
                    },
                    {
                        name: "资产总计",
                        value1:
                            Number(this.TotalCurrentAssetss[2]) +
                            Number(this.FlagTotalCurrentAssetss[2]),
                        value2:
                            Number(this.TotalCurrentAssetss[1]) +
                            Number(this.FlagTotalCurrentAssetss[1]),
                        code: "1046h",
                    }
                );
                this.balanceSheetMerge[3].push(
                    {
                        name: this.FlagTotalCurrentLiabilitys[0],
                        value1: this.FlagTotalCurrentLiabilitys[2],
                        value2: this.FlagTotalCurrentLiabilitys[1],
                        code: "1266h",
                    },
                    {
                        name: "负债合计",
                        value1:
                            Number(this.TotalCurrentLiabilitys[2]) +
                            Number(this.FlagTotalCurrentLiabilitys[2]),
                        value2:
                            Number(this.TotalCurrentLiabilitys[1]) +
                            Number(this.FlagTotalCurrentLiabilitys[1]),
                        code: "1069h",
                    }
                );
                this.balanceSheetMerge[4].push(
                    {
                        name: this.ownershipInterests[0],
                        value1: this.ownershipInterests[2],
                        value2: this.ownershipInterests[1],
                        code: "1076h",
                    },
                    {
                        name: "负债和所有者权益(或股东权益)合计",
                        value1:
                            Number(this.TotalCurrentLiabilitys[2]) +
                            Number(this.FlagTotalCurrentLiabilitys[2]) +
                            Number(this.ownershipInterests[2]),
                        value2:
                            Number(this.TotalCurrentLiabilitys[1]) +
                            Number(this.FlagTotalCurrentLiabilitys[1]) +
                            Number(this.ownershipInterests[1]),
                        code: "1617h",
                    }
                );

                var param = {
                    type: 'special',
                    company_code: this.company_code,
                    report_year: this.dateTime,
                    report_time: this.BType,
                    report_type: this.codetype,
                    tableDataSheet: this.balanceSheetMerge,
                    tableDataProfit: [],
                    tableDataCash: [],
                };
            } else {
                this.balanceSheet[0].push({
                    name: this.TotalCurrentAssets[0],
                    value1: this.TotalCurrentAssets[2],
                    value2: this.TotalCurrentAssets[1],
                    code: "1019h",
                });
                this.balanceSheet[1].push({
                    name: this.TotalCurrentLiability[0],
                    value1: this.TotalCurrentLiability[2],
                    value2: this.TotalCurrentLiability[1],
                    code: "1061h",
                });
                this.balanceSheet[2].push(
                    {
                        name: this.FlagTotalCurrentAssets[0],
                        value1: this.FlagTotalCurrentAssets[2],
                        value2: this.FlagTotalCurrentAssets[1],
                        code: "1254h",
                    },
                    {
                        name: "资产总计",
                        value1: Number(this.TotalCurrentAssets[2]) +
                            Number(this.FlagTotalCurrentAssets[2]),

                        value2: Number(this.TotalCurrentAssets[1]) +
                            Number(this.FlagTotalCurrentAssets[1]),
                        code: "1046h",
                    }
                );
                this.balanceSheet[3].push(
                    {
                        name: this.FlagTotalCurrentLiability[0],
                        value1: this.FlagTotalCurrentLiability[2],
                        value2: this.FlagTotalCurrentLiability[1],
                        code: "1266h",
                    },
                    {
                        name: "负债合计",
                        value1: Number(this.TotalCurrentLiability[2]) +
                            Number(this.FlagTotalCurrentLiability[2]),

                        value2: Number(this.TotalCurrentLiability[1]) +
                            Number(this.FlagTotalCurrentLiability[1]),

                        code: "1069h",
                    }
                );
                this.balanceSheet[4].push(
                    {
                        name: this.ownershipInterest[0],
                        value1: this.ownershipInterest[2],
                        value2: this.ownershipInterest[1],
                        code: "1076h",
                    },
                    {
                        name: "负债和所有者权益(或股东权益)合计",
                        value1:
                            Number(this.TotalCurrentLiability[2]) +
                            Number(this.FlagTotalCurrentLiability[2]) +
                            Number(this.ownershipInterest[2]),
                        value2:
                            Number(this.TotalCurrentLiability[1]) +
                            Number(this.FlagTotalCurrentLiability[1]) +
                            Number(this.ownershipInterest[1]),
                        code: "1617h",
                    }
                );
                var param = {
                    type: 'special',
                    company_code: this.company_code,
                    report_year: this.dateTime,
                    report_time: this.BType,
                    report_type: this.codetype,
                    tableDataSheet: this.balanceSheet,
                    tableDataProfit: [],
                    tableDataCash: [],
                };
            }
            hand_save(param).then((res) => {
                if (res.data.Status == 1) {
                    this.$Message.success(res.data.Message);
                    setTimeout(() => {
                        this.$router.push("/reportManagement");
                    }, 1000);
                } else {
                    this.$notify.error({
                        title: '提示',
                        message: res.data.Message
                    });
                }
            });


        },
        // 单体资产负债表合计项
        getSummariesOne(param) {
            const { columns, data } = param;
            var that = this;
            columns.forEach((column, index) => {
                if (index === 0) {
                    that.TotalCurrentAssets[index] = "流动资产合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                // console.log(values);
                if (!values.every((value) => isNaN(value))) {
                    that.TotalCurrentAssets[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            let num = Number(prev) + Number(curr)
                            return parseFloat(num).toFixed(2)
                        } else {
                            return prev;
                        }
                    }, 0);
                    that.TotalCurrentAssets[index];
                } else {
                    that.TotalCurrentAssets[index] = 0;
                }
            });
            that.$forceUpdate();
            return that.TotalCurrentAssets;
        },
        getSummariesTwo(param) {
            const { columns, data } = param;
            // const sums = [];
            var that = this;
            columns.forEach((column, index) => {
                if (index === 0) {
                    that.TotalCurrentLiability[index] = "流动负债合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (!values.every((value) => isNaN(value))) {
                    that.TotalCurrentLiability[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            let num = Number(prev) + Number(curr)
                            return parseFloat(num).toFixed(2)
                        } else {
                            return prev;
                        }
                    }, 0);
                    that.TotalCurrentLiability[index];
                } else {
                    that.TotalCurrentLiability[index] = 0;
                }
            });
            that.$forceUpdate();
            return that.TotalCurrentLiability;
        },
        getSummariesThree(param) {
            const { columns, data } = param;
            // const sums = [];
            var that = this;
            columns.forEach((column, index) => {
                if (index === 0) {
                    that.FlagTotalCurrentAssets[index] = "非流动资产合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (!values.every((value) => isNaN(value))) {
                    that.FlagTotalCurrentAssets[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            let num = Number(prev) + Number(curr)
                            return parseFloat(num).toFixed(2)
                        } else {
                            return prev;
                        }
                    }, 0);
                    that.FlagTotalCurrentAssets[index];
                } else {
                    that.FlagTotalCurrentAssets[index] = 0;
                }
            });

            that.$forceUpdate();
            return that.FlagTotalCurrentAssets;
        },
        getSummariesFour(param) {
            const { columns, data } = param;
            var that = this;
            columns.forEach((column, index) => {
                if (index === 0) {
                    that.FlagTotalCurrentLiability[index] = "非流动负债合计";
                    return;
                }
                const values = data.map((item, i) => Number(item[column.property]));
                if (index === 1) {
                    var num = values[1] +
                        values[2] +
                        values[5] +
                        values[6] +
                        values[7] +
                        values[8] +
                        values[9] +
                        values[10]
                    if (num !== 0) {
                        that.FlagTotalCurrentLiability[index] = parseFloat(num).toFixed(2);
                    } else {
                        that.FlagTotalCurrentLiability[index] = '0.00';
                    }
                }
                if (index === 2) {
                    var num = values[1] +
                        values[2] +
                        values[5] +
                        values[6] +
                        values[7] +
                        values[8] +
                        values[9] +
                        values[10]
                    if (num !== 0) {
                        that.FlagTotalCurrentLiability[index] = parseFloat(num).toFixed(2);
                    } else {
                        that.FlagTotalCurrentLiability[index] = '0.00';
                    }
                }
            });
            that.$forceUpdate();
            return that.FlagTotalCurrentLiability;
        },
        getSummariesFive(param) {
            const { columns, data } = param;
            var that = this;
            columns.forEach((column, index) => {
                if (index === 0) {
                    that.ownershipInterest[index] = "所有者权益(或股东权益)合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (index == 1) {
                    var num =
                        values[1] +
                        values[2] +
                        values[5] -
                        values[6] +
                        values[7] +
                        values[8] +
                        values[9] +
                        values[10];
                    if (num !== 0) {
                        that.ownershipInterest[index] = parseFloat(num).toFixed(2)
                    } else {
                        that.ownershipInterest[index] = '0.00';
                    }
                }
                if (index == 2) {
                    var num =
                        values[1] +
                        values[2] +
                        values[5] -
                        values[6] +
                        values[7] +
                        values[8] +
                        values[9] +
                        values[10];
                    if (num !== 0) {
                        that.ownershipInterest[index] = parseFloat(num).toFixed(2)
                    } else {
                        that.ownershipInterest[index] = '0.00';
                    }
                }
            });
            that.$forceUpdate();
            return that.ownershipInterest;
        },

        // 合并资产负债表合计项------------
        getSummariesOnes(param) {
            const { columns, data } = param;
            var that = this;
            columns.forEach((column, index) => {
                if (index === 0) {
                    that.TotalCurrentAssetss[index] = "流动资产合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (!values.every((value) => isNaN(value))) {
                    that.TotalCurrentAssetss[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            let num = Number(prev) + Number(curr)
                            return parseFloat(num).toFixed(2);
                        } else {
                            return prev;
                        }
                    }, 0);
                    that.TotalCurrentAssetss[index];
                } else {
                    that.TotalCurrentAssetss[index] = 0;
                }
            });
            that.$forceUpdate();

            return that.TotalCurrentAssetss;
        },
        getSummariesTwos(param) {
            const { columns, data } = param;
            // const sums = [];
            var that = this;
            columns.forEach((column, index) => {
                if (index === 0) {
                    that.TotalCurrentLiabilitys[index] = "流动负债合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (!values.every((value) => isNaN(value))) {
                    that.TotalCurrentLiabilitys[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            let num = Number(prev) + Number(curr)
                            return parseFloat(num).toFixed(2);
                        } else {
                            return prev;
                        }
                    }, 0);
                    that.TotalCurrentLiabilitys[index];
                } else {
                    that.TotalCurrentLiabilitys[index] = 0;
                }
            });
            that.$forceUpdate();
            return that.TotalCurrentLiabilitys;
        },
        getSummariesThrees(param) {
            const { columns, data } = param;
            // const sums = [];
            var that = this;
            columns.forEach((column, index) => {
                if (index === 0) {
                    that.FlagTotalCurrentAssetss[index] = "非流动资产合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (!values.every((value) => isNaN(value))) {
                    that.FlagTotalCurrentAssetss[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            let num = Number(prev) + Number(curr)
                            return parseFloat(num).toFixed(2);
                        } else {
                            return prev;
                        }
                    }, 0);
                    that.FlagTotalCurrentAssetss[index];
                } else {
                    that.FlagTotalCurrentAssetss[index] = 0;
                }
            });
            that.$forceUpdate();
            return that.FlagTotalCurrentAssetss;
        },
        getSummariesFours(param) {
            const { columns, data } = param;
            // const sums = [];
            var that = this;
            columns.forEach((column, index) => {
                if (index === 0) {
                    that.FlagTotalCurrentLiabilitys[index] = "非流动负债合计";
                    return;
                }
                const values = data.map((item, i) => Number(item[column.property]));
                if (index === 1) {
                    var num = values[1] +
                        values[2] +
                        values[3] +
                        values[6] +
                        values[7] +
                        values[8] +
                        values[9] +
                        values[10] +
                        values[11];
                    if (num !== 0) {
                        that.FlagTotalCurrentLiabilitys[index] = parseFloat(num).toFixed(2);
                    } else {
                        that.FlagTotalCurrentLiabilitys[index] = '0.00';
                    }
                }
                if (index === 2) {
                    var num = values[1] +
                        values[2] +
                        values[3] +
                        values[6] +
                        values[7] +
                        values[8] +
                        values[9] +
                        values[10] +
                        values[11];
                    if (num !== 0) {
                        that.FlagTotalCurrentLiabilitys[index] = parseFloat(num).toFixed(2);
                    } else {
                        that.FlagTotalCurrentLiabilitys[index] = '0.00';
                    }
                }
            });
            that.$forceUpdate();
            return that.FlagTotalCurrentLiabilitys;
        },
        getSummariesFives(param) {
            const { columns, data } = param;
            var that = this;
            columns.forEach((column, index) => {
                // console.log(column,index);
                if (index === 0) {
                    that.ownershipInterests[index] = "所有者权益(或股东权益)合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                if (index === 1) {
                    var num = values[12] + values[13];
                    if (num !== 0) {
                        that.ownershipInterests[index] = parseFloat(num).toFixed(2);
                    } else {
                        that.ownershipInterests[index] = '0.00';
                    }
                }
                if (index === 2) {
                    var num = values[12] + values[13];
                    if (num !== 0) {
                        that.ownershipInterests[index] = parseFloat(num).toFixed(2);
                    } else {
                        that.ownershipInterests[index] = '0.00';
                    }
                }
            });
            that.$forceUpdate();
            return that.ownershipInterests;
        },
        qyhj() {
            this.$refs.syzqy.columns.forEach((column, index) => {
                const values = this.$refs.syzqy.data.map((item) => Number(item[column.property]));
                if (index === 1) {
                    var num =
                        values[1] +
                        values[2] +
                        values[5] -
                        values[6] +
                        values[7] +
                        values[8] +
                        values[9] +
                        values[10] +
                        values[11]
                    this.$refs.syzqy.data[12].value2 = num
                }
                if (index === 2) {
                    var num =
                        values[1] +
                        values[2] +
                        values[5] -
                        values[6] +
                        values[7] +
                        values[8] +
                        values[9] +
                        values[10] +
                        values[11]
                    this.$refs.syzqy.data[12].value1 = num
                }
            });
        },
        // 取消返回
        back() {
            this.$router.push("/reportManagement");
        },
    },
};
</script>
  
<style lang="less" scoped>
/deep/.el-table__footer-wrapper tbody td {
    background-color: #fff !important;
}

/deep/.el-input-number .el-input__inner {
    text-align: left !important;
}

#handMadeFrom {
    padding-bottom: 50px;

    .tablesBox {
        /deep/.el-table__header-wrapper {
            height: 0px;
        }
    }

    #box {
        position: relative;
        margin-top: 40px;
        padding: 40px 60px 60px 60px;
        background: #ebf2f8;

        .ManageTop,
        .ManageBottom {
            background: #fff;
            border-radius: 4px;
            position: relative;
        }

        .ManageTop {
            padding: 25px;

            // height: 300px;
            .title {
                font-size: 18px;
                font-weight: 550;
            }

            .hardTop {
                margin-top: 30px;
                display: flex;
                align-items: center;
                padding-left: 10px;
                justify-content: space-between;
                padding-right: 50px;

                .TitleText {
                    padding-right: 10px;
                    font-size: 16px;
                    color: #333;
                }

                .box {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .ManageBottom {
            margin-top: 20px;
            padding: 20px 40px 40px 40px;

            .count {
                width: 100%;
                height: 52px;
                // background: #f5f7fa;
                padding: 15px 8px;
                font-weight: 600;
                color: #000;
                font-size: 14px;
                display: flex;
                justify-content: space-around;
                text-align: left;

                :nth-child(1) {
                    padding-left: 3px;
                }

                :nth-child(2) {
                    padding-left: 18px;
                }

                :nth-child(3) {
                    padding-left: 22px;
                }
            }

            /deep/.el-form-item {
                margin: 0 !important;
            }

            /deep/.el-form-item__content {
                margin: 0 !important;
            }

            /deep/.el-table td,
            .el-table th {
                padding: 5px 0px;
            }

            ::v-deep .el-table .el-table__footer-wrapper .cell {
                font-weight: 600;
                color: #000;
                height: 40px;
                line-height: 45px;
            }
        }
    }

    .flexBottom {
        background: #fff;
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        padding-right: 60px;
        justify-content: flex-end;
        position: fixed;
        bottom: 0;
        z-index: 999;
    }

    .contentInfoWrap /deep/ .el-table {
        display: flex;
        flex-direction: column;
    }

    /deep/ .contentInfoWrap .el-table__body-wrapper {
        order: 1;
    }

    /deep/ .contentInfoWrap .el-table__fixed-body-wrapper {
        top: 97px !important;
    }

    /deep/.contentInfoWrap .el-table__fixed-footer-wrapper {
        z-index: 0;
        top: 50px;
    }

    /deep/ .el-dialog__footer {
        text-align: center;
    }

    /deep/.el-input.is-disabled .el-input__inner {
        color: #333 !important
    }
}
</style>